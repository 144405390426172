<template>
  <dv-full-screen-container>
    <el-container class="home_container">
       <!--侧边-->
      <el-aside width="220px">
        <div class="logo_img">
          <img src="../assets/image/logo.png"/>
        </div>
        <el-menu unique-opened background-color="#304156" text-color="white" :default-active="this.$router.history.current.path"  class="el-menu-vertical-demo" :collapse-transition='false' router>
              <el-menu-item :index="'/homepage'" key="0" style="margin-right: 10px;">
                  <i class="el-icon-s-home"></i>
                  <span slot="title">主页</span>
              </el-menu-item>

              <el-submenu :index="item.id" v-for="item in listMenu" :key="item.id" :style="{marginRight: (item.id==3?'10px':'0px')}">
                <template slot="title">
                    <i :class="iconArray[item.id]"></i>
                    <span> {{item.name}}</span>
                </template>

                <el-menu-item :index="'/'+subitem.path"  v-for="subitem in item.chilidrens" :key="subitem.id" style="margin-left: 15px;">
                   <template slot="title">
                    <span> {{subitem.name}}</span>
                  </template>
                </el-menu-item>
              </el-submenu>
        </el-menu>
      </el-aside>

      <el-container>
        <!--头部-->
        <el-header>
          <div></div>
          <div class="remind">
            <div style="margin-right:50px">
              <div class="remind">
                <img src="../assets/image/daping.png" style="width: 25px;height: 25px;"/>
                <router-link style="color:#0E5FF6;font-size: 14px;margin-left: 5px;cursor:pointer;text-decoration:none" target="_blank" :to="{name:'datascreen',params:{token:tokenStr}}">数据大屏</router-link>
              </div>
            </div>
  
            <el-dropdown size='medium' @command='loginOut'>
              <span class="el-dropdown-link">
                <i class="el-icon-user el-icon-arrow-down"></i>
                {{login_name}}
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item icon="el-icon-key" command="a">修改密码</el-dropdown-item>
                <el-dropdown-item icon="el-icon-switch-button" command="b">退出登录</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
  
        </el-header>
  
        <!--主体-->
        <el-main>
          <router-view></router-view>
        </el-main>
      </el-container>
    </el-container>
  </dv-full-screen-container>
</template>

<script>
import {get} from '../util/util'



export default{
  data(){
    return{
      listMenu:[],
      login_name:'',
      tokenStr:'',
      iconArray: {
        1: 'el-icon-house',
        2: 'el-icon-s-data',
        3: 'el-icon-location-information',
        4: 'el-icon-document',
       
      },
    }
  },
  created(){
    this.listMenu = JSON.parse(window.sessionStorage.getItem('MeunItem'))
    this.login_name=JSON.parse(window.sessionStorage.getItem('rs_login_user')).user_name
    this.tokenStr=window.sessionStorage.getItem('rs_login_token')
  },
  mounted(){

  },
  methods:{
    loginOut (command) {
      if (command === 'a') {
        this.dialogVisible = true
      }
      if (command === 'b') {
        get('login/loginout')
        window.sessionStorage.removeItem('rs_login_token')
        this.$router.push('/')
      }
    },
  }
}
</script>

<style>
  .home_container{
  height: 100%;
}

.el-menu-item{
  padding: 0 40px;
}

._pwd_form{
  display: flex;
  flex-direction: column;
  justify-content: start;
}
._pwd_form div{
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
}
._pwd_form div>label{
  width: 100px;
}

.dropdown_btn{
float: right;
}

.logo_img{
height: 60px;
}
.logo_img img{
    max-height: 60px;
    width: 60px;
    display:flex;
    margin-left: 80px;
}




.remind{
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
}





.el-header {
    background-color: white;
    color: #333;
    line-height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .el-aside {
    background-color: #304156;
    color: #333;
    text-align: center;
    line-height: 230px;
    .el-menu{
      border-right: none;
    }
  }

  .el-main {
    background-color: #E9EEF3;
    color: #333;
    text-align: center;

  }

  body > .el-container {
    margin-bottom: 40px;
  }

  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }

  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }

.el-dropdown{
  float: right;
}

.el-dropdown-link {
    cursor: pointer;
}
.el-icon-arrow-down {
    font-size: 20px;
}

  ::v-deep .el-dialog__wrapper { 
  .el-dialog {
    .el-dialog__header{
      padding: 10px 20px 10px;
      background-color:#319FE4;
    }
    .el-dialog__headerbtn {
      top: 5px;
      right: 5px;
      padding-top: 10px;
    }
    .el-dialog__title,.el-dialog__headerbtn i{
      color: white;
    }
    .el-dialog__headerbtn .el-dialog__close {
      height: 30px;
      width: 35px;
    }
    .el-tab-pane{
        display:inline
    }
  }
  .el-dialog__body{
    .el-card__header{
      padding: 5px 10px;
      border-bottom:none;
      text-align: left;

    }
  }
}
</style>
